import { Divider } from '@material-ui/core';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { listAppointments } from '../../api/appointment';
import { useAppState } from '../../state';
import { Appointment } from '../../types';
import './DoctorNotifications.scss';

export default function DoctorNotifications() {
  const { appointment } = useAppState();
  const [nextAppointment, setNextAppointment] = useState<Appointment | null>(null);
  const [nextAppointmentTime, setNextAppointmentTime] = useState<string>('');
  const [patientName, setPatientName] = useState<string>('');

  const fetchDoctorAppointments = () => {
    listAppointments({
      doctor_uuid: appointment.doctor_user.uuid,
    }).then((response: any) => {
      let appointments: Array<Appointment> = response ?? [];

      appointments.forEach((item: Appointment) => {
        item.appointment_time_epoch = dayjs(item.appointment_time).valueOf();
      });

      appointments = _.sortBy(appointments, ['appointment_time_epoch']);

      let i = 0;
      let appointmentsLength = appointments.length;

      for (; i < appointmentsLength; i++) {
        if (appointments[i].appointment_id === appointment.appointment_id) {
          break;
        }
      }

      if (i + 1 < appointmentsLength) {
        let isNextAppointmentOnSameDay = dayjs().isSame(dayjs(appointments[i + 1].appointment_time), 'day');
        if (isNextAppointmentOnSameDay) {
          setNextAppointment(appointments[i + 1]);
        } else {
          setNextAppointment(null);
        }
      } else {
        setNextAppointment(null);
      }
    });
  };

  useEffect(() => {
    if (nextAppointment) {
      setPatientName(
        `${_.capitalize(nextAppointment.user.first_name)} ${_.capitalize(nextAppointment.user.last_name)}`
      );
      setNextAppointmentTime(dayjs(nextAppointment.appointment_time).format('HH:mm A'));
    }
  }, [nextAppointment]);

  useEffect(() => {
    let interval: any = null;

    if (appointment) {
      // Check the next doctor appointment every 5 minutes
      interval = setInterval(async () => {
        fetchDoctorAppointments();
      }, 300000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [appointment]);

  return (
    <div>
      {nextAppointment && (
        <div className="doctor-notification-block">
          <div className="doctor-notification-block__title">Notifications</div>
          <Divider light />
          <div className="doctor-notification-block__content">
            Your next appointment is with {patientName} at {nextAppointmentTime}
          </div>
        </div>
      )}
    </div>
  );
}
