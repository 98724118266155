import { Button, CircularProgress, Grid } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Conversation } from '@twilio/conversations/lib/conversation';
import React, { useEffect, useRef, useState } from 'react';
import FileAttachmentIcon from '../../../assets/icons/FileAttachmentIcon';
import { isMobile } from '../../../utils';
import PreviewImage from '../../PreviewImage/PreviewImage';
import Snackbar from '../../Snackbar/Snackbar';
import './ChatInput.scss';

interface ChatInputProps {
  conversation: Conversation;
  isChatWindowOpen: boolean;
}

const ALLOWED_FILE_TYPES =
  'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv';

export default function ChatInput({ conversation, isChatWindowOpen }: ChatInputProps) {
  const [messageBody, setMessageBody] = useState('');
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [mediaFile, setMediaFile] = useState<any>(null);

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(event.target.value);
  };

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = async (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      await handleSendMessage(messageBody);
    }
  };

  const handleSendMessage = async (message: string) => {
    if (isValidMessage) {
      if (mediaFile) {
        await sendMediaFile(mediaFile);
      }
      await conversation.sendMessage(message.trim());
      setMessageBody('');
      setMediaFile(null);
    } else {
      if (mediaFile) {
        await sendMediaFile(mediaFile);
        setMediaFile(null);
      }
    }
  };

  const sendMediaFile = async (file: any) => {
    try {
      var formData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setFileSendError(null);
      await conversation.sendMessage(formData);
    } catch (e) {
      if (e.code === 413) {
        setFileSendError('File size is too large. Maximum file size is 150MB.');
      } else {
        setFileSendError('There was a problem uploading the file. Please try again.');
      }
    } finally {
      setIsSendingFile(false);
    }
  };

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setMediaFile(file);
      // var formData = new FormData();
      // formData.append('userfile', file);
      // setIsSendingFile(true);
      // setFileSendError(null);
      // conversation
      //   .sendMessage(formData)
      //   .catch((e) => {
      //     if (e.code === 413) {
      //       setFileSendError('File size is too large. Maximum file size is 150MB.');
      //     } else {
      //       setFileSendError('There was a problem uploading the file. Please try again.');
      //     }
      //     // console.log('Problem sending file: ', e);
      //   })
      //   .finally(() => {
      //     setIsSendingFile(false);
      //   });
    }
  };

  const onMediaFileRemoved = () => {
    setMediaFile(null);
  };

  return (
    <div className="chat-block">
      <div className="chat-block__wrapper">
        <Snackbar
          open={Boolean(fileSendError)}
          headline="Error"
          message={fileSendError || ''}
          variant="error"
          handleClose={() => setFileSendError(null)}
        />
        <div className="chat-block__text-area-container">
          {/*
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
          <TextareaAutosize
            minRows={3}
            maxRows={3}
            aria-label="chat input"
            placeholder="Write a message..."
            onKeyPress={handleReturnKeyPress}
            onChange={handleChange}
            value={messageBody}
            data-cy-chat-input
            ref={textInputRef}
          />
        </div>

        <Grid container alignItems="flex-end" justifyContent="flex-end" wrap="nowrap" className="chat__grid">
          {/* Since the file input element is invisible, we can hardcode an empty string as its value.
        This allows users to upload the same file multiple times. */}
          <input ref={fileInputRef} type="file" onChange={handleSendFile} value={''} accept={ALLOWED_FILE_TYPES} />
          {mediaFile && (
            <div className="preview__container">
              <PreviewImage mediaFile={mediaFile} onRemove={onMediaFileRemoved} />
            </div>
          )}
          <div className="btn--file-send__container">
            <div className="btn--file-send__attachment-container">
              <Button className="btn--file-send" onClick={() => fileInputRef.current?.click()} disabled={isSendingFile}>
                <FileAttachmentIcon />
              </Button>

              {isSendingFile && <CircularProgress size={24} className="spinner" />}
            </div>

            <Button
              className="btn--send btn--reg"
              onClick={() => handleSendMessage(messageBody)}
              color="secondary"
              variant="contained"
              disabled={!isValidMessage && !mediaFile}
              data-cy-send-message-button
            >
              Send
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  );
}
