import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  List,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import React, { useEffect, useState } from 'react';
import { useAppState } from '../../state';
import {
  DOCTOR_TYPE,
  MEDICARE_APPOINTMENT_TYPES,
  MEDICARE_APPOINTMENT_DURATIONS
} from '../../utils/constants';
import './EndCallReasonDialog.scss';

export interface EndCallReasonDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (data: any) => void;
}

export default function EndCallReasonDialog(props: EndCallReasonDialogProps) {
  const { appointment } = useAppState();
  const { onClose, open } = props;
  const [reasonContent, setReasonContent] = useState<string>('');
  const [additionalReason, setAdditionalReason] = useState<string>('');
  const [error, setError] = useState(false);
  const [medicareCode, setMedicareCode] = useState<string>('');
  const [mhcpProvided, setMhcpProvided] = useState<string>('');
  const [medicareAppointmentType, setMedicareAppointmentType] = useState<string>('');
  const [medicareAppointmentDuration, setMedicareAppointmentDuration] = useState<string>('');
  const [reasons, setReasons] = useState<Array<any>>([]);
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const [showMedicareCodes, setshowMedicareCodes] = useState<boolean>(false);
  const [showMhcpProvided, setshowMhcpProvided] = useState<boolean>(false);
  const [showAppointmentType, setshowAppointmentType] = useState<boolean>(false);
  const [showAppointmentDuration, setshowAppointmentDuration] = useState<boolean>(false);
  const [helperText, setHelperText] = useState('');
  const [medicareCodeList, setMedicareCodeList] = useState<Array<any>>([]);
  const [medicareAppointmentTypeList, setMedicareAppointmentTypeList] = useState<Array<any>>([]);
  const [medicareAppointmentDurationList, setMedicareAppointmentDurationList] = useState<Array<any>>([]);

  useEffect(() => {
    let doctorType = appointment.doctor_user.doctor_type.toLocaleLowerCase();
    let appointmentType = appointment.appointment_type;
    if (doctorType === DOCTOR_TYPE.GP) {
      let endCallReasons = appointment.vc_end_call_reasons;
      setReasons(endCallReasons);
    } else if (doctorType === DOCTOR_TYPE.PSYCHOLOGIST) {
      let endCallReasons = appointment.vc_end_call_reasons;
      setReasons(endCallReasons);
    } else if (doctorType === DOCTOR_TYPE.DIETITIAN) {
      let endCallReasons = appointment.vc_end_call_reasons;
      setReasons(endCallReasons);
    } else if (doctorType === DOCTOR_TYPE.NURSE_PRACTITIONER) {
      let endCallReasons = appointment.vc_end_call_reasons;
      setReasons(endCallReasons);
      setMedicareCodeList(appointment.vc_medicare_codes);
    }else{
      let endCallReasons = appointment.vc_end_call_reasons;
      setReasons(endCallReasons);
    }
  }, [appointment]);

  const handleClose = () => {
    onClose(null);
  };

  const handleConfirm = () => {
    let doctorType = appointment.doctor_user.doctor_type.toLocaleLowerCase();
    let appointmentType = appointment.appointment_type;
    if (reasonContent === '') {
      setHelperText('Please check all selections');
      setError(true);
    } else if ((appointmentType == "MHCP" || appointmentType == "Mental Health GP" || appointmentType == "MHCP Renewing") && (medicareCode === '') && (showMhcpProvided === true)){
      setHelperText('Please check all selections');
      setError(true);
    } else if ((doctorType === DOCTOR_TYPE.NURSE_PRACTITIONER) && (medicareCode === '') && (showMedicareCodes === true)){
      setHelperText('Please check all selections');
      setError(true);
    } else {
      onClose({
        reasonContent,
        additionalReason,
        medicareCode,
        medicareAppointmentType,
        medicareAppointmentDuration
      });
    }
  };

  const handleListItemClick = (event: any, value: any) => {
    let filteredReasons = reasons.filter((item) => item.content === value);
    let appointmentType = appointment.appointment_type;
    if (filteredReasons.length > 0) {
      setShowTextArea(filteredReasons[0].needFreeTextField);
      setshowMedicareCodes(filteredReasons[0].needMedicareCode);
      if (appointmentType == "MHCP" || appointmentType == "Mental Health GP" || appointmentType == "MHCP Renewing") {
        setshowMhcpProvided(filteredReasons[0].needMedicareCode);
        setMhcpProvided('');
        setshowMedicareCodes(false);
        setMedicareCode('');
      }
    }

    setReasonContent(value);
    setError(false);
    setHelperText('');
    setAdditionalReason('');
  };

  const onAdditionalReasonChange = (e: any) => {
    setAdditionalReason(e.target.value);
  };

  const setMhcpMedicareCode = (value: any) => {
    setMhcpProvided(value);
    setMedicareCodeList([]);
    setshowMedicareCodes(true);
    if (value === 'Yes') {
      setMedicareCodeList(appointment.vc_medicare_codes.filter((item) => item.mhcp_provided === true));
    } else if (value === 'No') {
      setMedicareCodeList(appointment.vc_medicare_codes.filter((item) => item.mhcp_provided === false));
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className="end-call-dialog">
      <DialogTitle className="end-call-dialog__title" disableTypography={true}>
        <h2>Select a reason for ending this call</h2>
      </DialogTitle>
      <DialogContent className="end-call-dialog__content">
        <FormControl component="fieldset" error={error}>
          {reasons.map((reason, index) => (
            <div key={index} className={`end-call-dialog__option ${reason.line_break_after ? 'space-after' : ''}`}>
              <Checkbox
                checked={reasonContent === reason.content}
                icon={<CircleUnchecked color="primary" />}
                checkedIcon={<CircleCheckedFilled />}
                onChange={(e) => {
                  handleListItemClick(e, reason.content);
                }}
              />
              <div className="text">{reason.content}</div>
            </div>
          ))}

          <Input
            value={additionalReason}
            placeholder="Please write any additional info here..."
            disableUnderline={true}
            className={`end-call-dialog__reason input_grey ${showTextArea ? '' : 'hide'}`}
            multiline={true}
            onChange={onAdditionalReasonChange}
          />
        </FormControl>
      </DialogContent>

      <DialogContent className="end-call-dialog__content">
        <FormControl component="fieldset" error={error} className={`${showMhcpProvided ? '' : 'hide-component'}`}>
          <InputLabel>MHCP initial / review provided?</InputLabel>
          <Select onChange={(e) => setMhcpMedicareCode(e.target.value as string)} value={mhcpProvided} variant="outlined">
            <MenuItem value="" key="">
              Please select
            </MenuItem>
            <MenuItem value="Yes" key="Yes">
              Yes
            </MenuItem>
            <MenuItem value="No" key="No">
              No
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>

      <DialogContent className="end-call-dialog__content">
        <FormControl component="fieldset" error={error} className={`${showMedicareCodes ? '' : 'hide-component'}`}>
          <InputLabel>Medicare code</InputLabel>
          <Select onChange={(e) => setMedicareCode(e.target.value as string)} value={medicareCode} variant="outlined">
            {medicareCodeList.map((medicare_code) => (
              <MenuItem value={medicare_code.value} key={medicare_code.value}>
                {medicare_code.content}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogContent className="end-call-dialog__content">
        <FormControl component="fieldset" error={error} className={`${showAppointmentType ? '' : 'hide-component'}`}>
          <InputLabel>Appointment type</InputLabel>
          <Select onChange={(e) => setMedicareAppointmentType(e.target.value as string)} value={medicareAppointmentType} variant="outlined">
            {medicareAppointmentTypeList.map((medicare_appointment_type) => (
              <MenuItem value={medicare_appointment_type.value} key={medicare_appointment_type.value}>
                {medicare_appointment_type.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogContent className="end-call-dialog__content">
        <FormControl component="fieldset" error={error} className={`${showAppointmentDuration ? '' : 'hide-component'}`}>
          <InputLabel>Appointment Duration</InputLabel>
          <Select onChange={(e) => setMedicareAppointmentDuration(e.target.value as string)} value={medicareAppointmentDuration} variant="outlined">
            {medicareAppointmentDurationList.map((medicare_appointment_duration) => (
              <MenuItem value={medicare_appointment_duration.value} key={medicare_appointment_duration.value}>
                {medicare_appointment_duration.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogContent className="end-call-dialog__content">
        <FormControl component="fieldset" error={error}>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </DialogContent>

      <DialogActions className="end-call-dialog__actions">
        <Button variant="contained" color="secondary" className='btn--green' onClick={handleConfirm}>
          Save &amp; Close
        </Button>
      </DialogActions>

      <List></List>
    </Dialog>
  );
}
