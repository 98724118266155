import { makeStyles } from '@material-ui/core/styles';
import { Media } from '@twilio/conversations/lib/media';
import React, { useEffect, useState } from 'react';
import FileDownloadIcon from '../../../../assets/icons/FileDownloadIcon';

const useStyles = makeStyles({
  messageContainer: {
    display: 'flex',
    padding: '0.9em 1.5em',
    margin: '0.6em 0',
    border: '2px solid #e4e7e9',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mediaInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '1.5em',
    minWidth: 0,
    '& p': {
      margin: 0,
      fontSize: '12px',
    },
  },
  filename: {
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  size: {
    fontWeight: 400,
  },
  previewImage: {
    width: '100%',
    borderRadius: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface MediaMessageProps {
  media: Media;
}

const IMAGE_EXTENSION = ['jpg', 'jpeg', 'png'];

export function formatFileSize(bytes: number, suffixIndex = 0): string {
  const suffixes = ['bytes', 'KB', 'MB', 'GB'];
  if (bytes < 1000) return +bytes.toFixed(2) + ' ' + suffixes[suffixIndex];
  return formatFileSize(bytes / 1024, suffixIndex + 1);
}

export default function FileMessage({ media }: MediaMessageProps) {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (isImageFile()) {
      media.getContentTemporaryUrl().then((url) => {
        setImageUrl(url);
      });
    }
  }, [media]);

  const handleClick = () => {
    media.getContentTemporaryUrl().then((url) => {
      const anchorEl = document.createElement('a');

      anchorEl.href = url;
      anchorEl.target = '_blank';
      anchorEl.rel = 'noopener';

      // setTimeout is needed in order to open files in iOS Safari.
      setTimeout(() => {
        anchorEl.click();
      });
    });
  };

  const isImageFile = () => {
    let split = media.filename.split('.');

    if (split.length > 0) {
      let extension = split[split.length - 1];

      return IMAGE_EXTENSION.includes(extension);
    }

    return false;
  };

  return (
    <div>
      {isImageFile() ? (
        <div onClick={handleClick}>
          <img alt={media.filename} className={classes.previewImage} src={imageUrl} />
        </div>
      ) : (
        <div className={classes.messageContainer + ' message_container'} onClick={handleClick}>
          <div className={classes.iconContainer}>
            <FileDownloadIcon />
          </div>
          <div className={classes.mediaInfo}>
            <p className={classes.filename}>{media.filename}</p>
            <p className={classes.size}>{formatFileSize(media.size)} - Click to open</p>
          </div>
        </div>
      )}
    </div>
  );
}
