import { Grid, Hidden, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { setInterval } from 'timers';
import Watch from '../../assets/images/Watch.png';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { isMobile } from '../../utils';
import { DOCTOR_TYPE, REBOOK_URL, USER_TYPE } from '../../utils/constants';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import MobileTogglePatientChatButton from '../Buttons/MobileToggleChatButton/MobileToggleChatButton';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import BackgroundControlButton from '../Buttons/BackgroundControlButton/BackgroundControlButton';
import ToggleFullscreenButton from '../Buttons/ToggleFullscreenButton/ToggleFullscreenButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import './MenuBar.scss';
import Menu from './Menu/Menu';

export default function MenuBar() {
  const { appointment, userType, togglePatientInfo, setTogglePatientInfo } = useAppState();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const [remainingDuration, setRemainDuration] = useState<number>(0);
  const [formattedRemainingDuration, setFormattedRemainingDuration] = useState<string>('');
  const [isRebookVisible, setIsRebookVisible] = useState<boolean>(false);
  const [countDownInitialCheckInterval, setCountDownInitialCheckInterval] = useState<any>(null);
  const [countdownInterval, setCountdownInterval] = useState<any>(null);
  const participants = useParticipants();

  const participantsRef = useRef(participants);
  const countDownInitialCheckIntervalRef = useRef(countDownInitialCheckInterval);
  const remainingDurationRef = useRef(remainingDuration);
  const countdownIntervalRef = useRef(countdownInterval);

  useEffect(() => {
    participantsRef.current = participants;
    countDownInitialCheckIntervalRef.current = countDownInitialCheckInterval;
    remainingDurationRef.current = remainingDuration;
    countdownIntervalRef.current = countdownInterval;
  });

  useEffect(() => {
    if (USER_TYPE.DOCTOR === userType) {
      let startTime = dayjs(appointment.appointment_time);

      let endTime = startTime.add(parseInt(appointment.duration), 'minute');

      let now = dayjs();

      let diff = endTime.diff(now);

      let seconds = diff / 1000;

      let isPsychologist =
        userType === USER_TYPE.DOCTOR &&
        appointment.doctor_user.doctor_type.toLocaleLowerCase() === DOCTOR_TYPE.PSYCHOLOGIST;

      let duration = isPsychologist ? 50 : parseInt(appointment.duration, 10);

      // seconds = parseInt(appointment.duration) * 60;
      // setRemainDuration(seconds);
      // setUpCountDown();

      // If current time is after start time, then calculate from beginning of the point
      if (now.isAfter(startTime)) {
        seconds = duration * 60;
        setRemainDuration(seconds);
        setUpCountDown();
      } else {
        // Only doctor joined in, keep checking
        if (participantsRef.current.length === 0) {
          if (!countDownInitialCheckIntervalRef.current) {
            let interval = setInterval(() => {
              if (participantsRef.current.length >= 1) {
                let startTime = dayjs(appointment.appointment_time);

                let endTime = startTime.add(duration, 'minute');

                let now = dayjs();

                let diff = endTime.diff(now);

                let seconds = diff / 1000;

                if (remainingDurationRef.current === 0) {
                  if (now.isAfter(startTime)) {
                    setRemainDuration(seconds);
                  } else {
                    setRemainDuration(duration * 60);
                  }
                  setUpCountDown();
                  clearInterval(interval);
                  setCountDownInitialCheckInterval(null);
                }
              }
            }, 1000);
            setCountDownInitialCheckInterval(interval);
            countDownInitialCheckIntervalRef.current = interval;

            clearInterval(interval);
          }
        } else {
          // Patient and doctor are all joining in, then calculate from
          seconds = duration * 60;
          setRemainDuration(seconds);
          setUpCountDown();
        }
      }
    }

    return () => {
      clearTimeInterval();
      clearCountDownInterval();
    };
  }, []);

  const clearTimeInterval = () => {
    if (countDownInitialCheckIntervalRef.current) {
      clearInterval(countDownInitialCheckIntervalRef.current);
      setCountDownInitialCheckInterval(null);
    }
  };

  useEffect(() => {
    let isPsychologist =
      userType === USER_TYPE.DOCTOR &&
      appointment.doctor_user.doctor_type.toLocaleLowerCase() === DOCTOR_TYPE.PSYCHOLOGIST;

    setIsRebookVisible(isPsychologist);
  }, [appointment, userType]);

  const clearCountDownInterval = () => {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      setCountdownInterval(null);
    }
  };

  const setUpCountDown = () => {
    let interval: any = null;
    interval = setInterval(() => {
      setRemainDuration(remainingDurationRef.current - 1);
      if (remainingDurationRef.current > 0) {
        setFormattedRemainingDuration(new Date(remainingDurationRef.current * 1000).toISOString().substr(14, 5));
        // }
      } else {
        let res = Math.abs(remainingDurationRef.current);
        setFormattedRemainingDuration('-' + new Date(res * 1000).toISOString().substr(14, 5));
        // }
      }
    }, 1000);

    setCountdownInterval(interval);
  };

  const onClickPatientInfo = () => {
    setTogglePatientInfo(!togglePatientInfo);
  };

  const onRebookClick = () => {
    window.open(`${REBOOK_URL}?user_id=${appointment.user.uuid}`, '_blank');
  };

  return (
    <>
      {!isMobile && isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className="screen-share-banner-block">
          <Typography variant="h6">You are sharing your screen.</Typography>
          <Button size="small" onClick={() => toggleScreenShare()}>
            Stop sharing
          </Button>
        </Grid>
      )}
      <footer className="footer-block">
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid className="placeholder"></Grid>
          </Hidden>
          <Grid item className="footer-block__control_bar">
            <Grid container justifyContent="space-around">
              <Hidden smDown>
                <ToggleFullscreenButton />
              </Hidden>
              <MobileTogglePatientChatButton />
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {!isMobile && userType === USER_TYPE.DOCTOR && <ToggleScreenShareButton disabled={isReconnecting} />}
              {/*{process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}*/}
              {/*<Hidden smDown>*/}
              {/*  <Menu />*/}
              {/*</Hidden>*/}
              <BackgroundControlButton disabled={isReconnecting} />
              <EndCallButton />
            </Grid>
          </Grid>
          <div className={'footer-block__option-bar ' + (userType === 'user' ? 'option_bar_user' : '')}>
            {userType === USER_TYPE.DOCTOR && countdownInterval && (
              <div className={'footer-block__time-label mobile ' + (remainingDurationRef.current <= 0 ? 'red' : '')}>
                <img alt="watch" src={Watch} />
                <div>
                  {formattedRemainingDuration} mins
                  <span className="mins-remaining"> remaining</span>
                  <span className="mins-left"> left</span>
                </div>
              </div>
            )}
            <div className="btn-rebook__container">
              {isRebookVisible && (
                <Button color="secondary" className="btn--green btn-rebook" variant="outlined" onClick={onRebookClick}>
                  Rebook
                </Button>
              )}
            </div>
            <div className="footer-block__patient_info">
              {userType === USER_TYPE.DOCTOR && <div onClick={onClickPatientInfo}>PATIENT INFO</div>}
            </div>
          </div>
          <Grid className="time-remaining--desktop placeholder red">
            <Grid container justifyContent="flex-end">
              {userType === USER_TYPE.DOCTOR && countdownInterval && (
                <div className={'footer-block__time-label ' + (remainingDurationRef.current <= 0 ? 'red' : '')}>
                  <img alt="watch" src={Watch} />
                  <div>
                    {formattedRemainingDuration} mins
                    <span className="mins-remaining"> remaining</span>
                    <span className="mins-left"> left</span>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
