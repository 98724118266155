import { Button, Fade, Grid, Hidden, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateVcAccessToken, getAppointmentById } from '../../../api/appointment';
import { createAppointmentLog } from '../../../api/appointment_log';
import Watch from '../../../assets/images/watch.svg';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import { checkIsEarlyAppointment, formatAppointmentTime } from '../../../utils';
import { BEST_BROWSERS_MESSAGE, WAITING_ROOM_MESSAGES } from '../../../utils/constants';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import GameSnake from '../../GameSnake/GameSnake';
import { Steps } from '../PreJoinScreens';
import './InitialScreen.scss';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

function PatientInitialScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const { appointment, vcUser } = useAppState();
  const { isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { UrlAppointmentId } = useParams();
  const { getAudioAndVideoTracks } = useVideoContext();
  const [showGame, setShowGame] = useState(false);
  const [isCheckDevice, setIsCheckDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isApologyDialogVisible, setIsApologyDialogVisible] = useState<boolean>(false);
  const [isApologyDialogDismissed, setIsApologyDialogDismissed] = useState<boolean>(false);
  const [waitingRoomMessage, setWaitingRoomMessage] = useState<string>(WAITING_ROOM_MESSAGES.DEFAULT);
  const [joinNowBtnVisible, setJoinNowVisible] = useState<boolean>(false);
  const [apologyCheckInterval, setApologyCheckInterval] = useState<any>(null);
  const [appointmentInterval, setAppointmentInterval] = useState<any>(null);
  const [isEarlyAppointment, setIsEarlyAppointment] = useState<boolean>(false);
  const [formattedAppointmentTime, setFormattedAppointmentTime] = useState<string>('');

  const appointmentRef = useRef(appointment);
  const joinNowBtnVisibleRef = useRef(joinNowBtnVisible);
  const vcUserRef = useRef(vcUser);
  const appointmentIntervalRef = useRef(appointmentInterval);
  const joinBtnRef = useRef(null);

  useEffect(() => {
    appointmentRef.current = appointment;
    joinNowBtnVisibleRef.current = joinNowBtnVisible;
    vcUserRef.current = vcUser;
    appointmentIntervalRef.current = appointmentInterval;
  });

  useEffect(() => {
    let mounted = true;

    setLoading(true);

    // First check
    getAppointmentById({
      appointmentId: UrlAppointmentId,
    })
      .then(async (response: any) => {
        if (mounted) {
          let needRealtimeCheck = true;

          let isEarly = checkIsEarlyAppointment(response);

          setIsEarlyAppointment(isEarly);

          if (isEarly) {
            setFormattedAppointmentTime(formatAppointmentTime(response.appointment_time));
          }

          apologyDialogCheck(response);
          if (response.vc_room_id) {
            setWaitingRoomMessage(WAITING_ROOM_MESSAGES.PATIENT_JOINED_AFTER);
            // setIsAllowedJoin(true);
            setJoinNowVisible(true);

            needRealtimeCheck = false;

            clearAppointmentInterval();
          }

          if (needRealtimeCheck) {
            let interval = setInterval(async () => {
              if (appointmentRef.current && appointmentRef.current.vc_room_id) {
                setWaitingRoomMessage(WAITING_ROOM_MESSAGES.PATIENT_JOINED_BEFORE);
                clearAppointmentInterval();
                // @ts-ignore
                joinBtnRef.current.click();
              }
            }, 5000);

            setAppointmentInterval(interval);
          } else {
            clearAppointmentInterval();
          }

          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });

    return () => {
      if (apologyCheckInterval) {
        clearInterval(apologyCheckInterval);
        setApologyCheckInterval(null);
      }
      clearAppointmentInterval();
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const initDevice = async () => {
      try {
        await getAudioAndVideoTracks();
      } catch (e) {
        console.dir(e);
      }
    };

    initDevice();
  }, [getAudioAndVideoTracks]);

  const apologyDialogCheck = (appointmentParams: any) => {
    if (appointmentParams && !isApologyDialogVisible && !isApologyDialogDismissed) {
      let startTime = dayjs(appointmentParams.appointment_time);

      let now = dayjs();

      if (now.isAfter(startTime)) {
        setIsApologyDialogVisible(true);
      }
    }
  };

  useEffect(() => {
    setApologyCheckInterval(
      setInterval(async () => {
        apologyDialogCheck(appointment);
      }, 10000)
    );

    return () => {
      if (apologyCheckInterval) {
        clearInterval(apologyCheckInterval);
        setApologyCheckInterval(null);
      }
    };
  }, [appointment, isApologyDialogVisible, isApologyDialogDismissed]);

  const clearAppointmentInterval = () => {
    if (appointmentIntervalRef.current) {
      clearInterval(appointmentIntervalRef.current);
      setAppointmentInterval(null);
    }
  };

  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        className="loading-spinner__container"
      >
        <div className="loading-spinner--large">
          <CircularProgress size={30} variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2">
            <strong>Joining meeting</strong>
          </Typography>
        </div>
      </Grid>
    );
  }

  const handleJoin = async (identity: string, rName: string) => {
    console.info('Join session...');

    // commenting changing loading state since it will have no effect once the session has been joined
    // setLoading(true);

    let response2: any = await generateVcAccessToken({
      identity,
      room_name: rName,
    });

    await videoConnect(response2.access_token);
    if (process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true') chatConnect(response2.access_token);

    createAppointmentLog({
      appointment_id: appointment.appointment_id,
      action_name: 'Patient joined',
      creator_type: 'User',
      creator_uuid: appointment?.user?.uuid ?? '',
    });

    // setLoading(false);

    // getToken(name, roomName).then(({ token }) => {
    //   videoConnect(token);
    //   process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    // });
  };

  const onClickJoin = async () => {
    await handleJoin(vcUserRef.current?.email ?? '', appointmentRef.current?.vc_room_name ?? '');
  };

  const onClickShowGame = () => {
    setShowGame(true);
  };

  const onExitGame = () => {
    setShowGame(false);
  };

  const onClickTestDevice = () => {
    setIsCheckDevice(!isCheckDevice);
  };

  const dismissDialog = () => {
    setIsApologyDialogVisible(false);
    setIsApologyDialogDismissed(true);
    if (apologyCheckInterval) {
      clearInterval(apologyCheckInterval);
      setApologyCheckInterval(null);
    }
  };

  return (
    <div>
      <Dialog
        open={isApologyDialogVisible}
        onClose={() => dismissDialog()}
        fullWidth={true}
        maxWidth="xs"
        className="apology-dialog"
      >
        <DialogContent>
          <div className="apology-dialog__icon">
            <img alt="watch" src={Watch} />
          </div>
          <DialogContentText className="apology-dialog__content">
            Apologies, your practitioner is running a little bit behind. They'll be with you as soon as possible.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="apology-dialog__actions">
          <a className="btn--shadowed flex-1" onClick={dismissDialog}></a>
        </DialogActions>
      </Dialog>
      <Fade in={true}>
        <div>
          <Typography variant="h2" className="user_name">
          {appointment?.doctor_user?.title ?? ''} {appointment?.doctor_user?.first_name ?? ''}'s room
          </Typography>

          {isEarlyAppointment && (
            <div className="consult-message">Your appointment will begin on {formattedAppointmentTime}</div>
          )}

          <div className="consult-message">{waitingRoomMessage}</div>

          <div className="consult-message">{BEST_BROWSERS_MESSAGE}</div>

          <h5 className="link-device-test" onClick={onClickTestDevice}>
            Test speaker and microphone
          </h5>

          <Grid container justifyContent="center" className="test_buttons">
            <Grid item md={12} sm={12} xs={12}>
              {isCheckDevice && (
                <div className="video-preview__container">
                  <div className="video-preview">
                    <LocalVideoPreview identity={name} />
                  </div>
                  <div className="btn-mobile-container">
                    <Hidden mdUp>
                      <ToggleAudioButton disabled={disableButtons} />
                      <ToggleVideoButton disabled={disableButtons} />
                    </Hidden>
                  </div>
                  {isCheckDevice && (
                    <div className="video-preview__buttons text-align-center">
                      <Hidden smDown>
                        <ToggleAudioButton disabled={disableButtons} />
                        <ToggleVideoButton disabled={disableButtons} />
                      </Hidden>
                    </div>
                  )}
                </div>
              )}
              <div className={`text-align-center join-btn__container ${joinNowBtnVisible ? '' : 'hide'}`}>
                {/*<Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>*/}
                {/*  Cancel*/}
                {/*</Button>*/}
                <Button
                  className="margin-1"
                  variant="contained"
                  color="primary"
                  ref={joinBtnRef}
                  data-cy-join-now
                  onClick={onClickJoin}
                  disabled={loading || disableButtons}
                  startIcon={loading ? <CircularProgress color="primary" size={12} /> : ''}
                >
                  Join now
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Fade>
      {showGame && (
        <Fade in={true}>
          <div>
            <GameSnake onExitGame={onExitGame} />
          </div>
        </Fade>
      )}
      {!loading && !showGame && !joinNowBtnVisible && (
        <div className="game-block">
          <Button className="btn--green" onClick={onClickShowGame}>
            Play while you wait
          </Button>
        </div>
      )}
    </div>
  );
}

export default PatientInitialScreen;
