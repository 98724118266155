import React from 'react';
import './MessageInfo.scss';

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}

export default function MessageInfo({ author, dateCreated, isLocalParticipant }: MessageInfoProps) {
  return (
    <div className="message-info-block">
      {/*<div>{isLocalParticipant ? `${author} (You)` : author}</div>*/}
      <div>{dateCreated}</div>
    </div>
  );
}
