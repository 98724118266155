import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useAppState } from '../../state';
import { DOCTOR_HOME_URL, USER_HOME_URL, USER_TYPE } from '../../utils/constants';
import './TopNavBar.scss';

const topBarTheme = {
  colorPrimary: 'white',
};

export default function TopNavBar() {
  const { appointment, userType } = useAppState();
  const [userHomeUrl, setUserHomeUrl] = useState<string>(`${process.env.REACT_APP_USER_PORTAL_BASE_URL}`);

  useEffect(() => {
    let homeUrl = userType === USER_TYPE.USER ? USER_HOME_URL : DOCTOR_HOME_URL;

    setUserHomeUrl(homeUrl);
  }, [appointment, userType]);

  return (
    <div className="navbar-block">
      <ThemeProvider theme={topBarTheme}>
        <AppBar position="static" className="inner-block">
          <Toolbar>
            <a href={userHomeUrl}>
              <Typography variant="h6" className="logo">
                <img
                  alt="mosh logo"
                  src={
                    'https://www.getmosh.com.au/assets/logo/logo_dark-a8f123fc6a5cead8cbe90b9d4729a824d99f5ec1fe7b8d4548f6ff46c9096564.svg'
                  }
                ></img>
              </Typography>
            </a>
            <div className="btn--logout">
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Log out
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </div>
  );
}
