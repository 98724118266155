import { service } from '../services/request';

export function createMedicalNotes(data: any) {
  return service({
    url: '/api/v1/medical_notes',
    method: 'post',
    data,
  });
}

export function updateMedicalNotes(data: any) {
  return service({
    url: `/api/v1/medical_notes/${data.id}`,
    method: 'put',
    data,
  });
}

export function deleteMedicalNote(data: any) {
  return service({
    url: `/api/v1/medical_notes/${data.id}`,
    method: 'delete',
  });
}

export function getMedicalNote(params: any) {
  return service({
    url: `/api/v1/medical_notes`,
    method: 'get',
    params,
  });
}
