import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import MainParticipant from '../MainParticipant/MainParticipant';
import MenuBar from '../MenuBar/MenuBar';
import ParticipantList from '../ParticipantList/ParticipantList';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${
    theme.sidebarMobileHeight + theme.sidebarMobilePadding * 2 + theme.participantBorderWidth
  }px`;
  return {
    container: {
      position: 'relative',
      height: '60vh',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 64px)',
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px` },
  };
});

export default function Room() {
  const classes = useStyles();
  const { isBackgroundSelectionOpen } = useVideoContext();
  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]: isBackgroundSelectionOpen,
      })}
    >
      <MainParticipant />
      <ParticipantList />
      <BackgroundSelectionDialog />
      <MenuBar />
    </div>
  );
}
