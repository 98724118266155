import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PatientEndScreen from '../../assets/images/PatientEndScreen.svg';
import { useAppState } from '../../state';
import { DOCTOR_HOME_URL, HOME_URL } from '../../utils/constants';
import './DoctorEndCallDialog.scss';

export default function DoctorEndCallDialog() {
  const [patientName, setPatientName] = useState('');

  const { isDoctorEndCallDialogVisible, appointment } = useAppState();

  const onClickBackToYourAccount = (e: any) => {
    e.preventDefault();
    window.location.href = DOCTOR_HOME_URL;
    console.log(DOCTOR_HOME_URL);
  };

  useEffect(() => {
    if (appointment) {
      setPatientName(`${_.capitalize(appointment.user?.first_name)} ${_.capitalize(appointment.user?.last_name)}`);
    }
  }, [appointment]);

  return (
    <Dialog open={isDoctorEndCallDialogVisible} fullWidth={true} maxWidth="xs" className="doctor-end-screen-dialog">
      <AppBar position="static" className="inner-block">
        <Toolbar>
          <a href={HOME_URL}>
            <Typography variant="h6" className="logo">
              <img
                alt="mosh logo"
                src={
                  'https://www.getmosh.com.au/assets/logo/logo_dark-a8f123fc6a5cead8cbe90b9d4729a824d99f5ec1fe7b8d4548f6ff46c9096564.svg'
                }
              ></img>
            </Typography>
          </a>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <img src={PatientEndScreen} />

        <h4 className="header1">
          <div>Thanks!</div>
        </h4>
        <div className="content">
          <div>Your session with {patientName} has ended.</div>
        </div>
        <Button className="btn--lrg" onClick={onClickBackToYourAccount}>
          Back to account
        </Button>
      </DialogContent>
    </Dialog>
  );
}
