import React, { useEffect, useState } from 'react';
import { Participant, Track } from 'twilio-video';
import usePublications from '../../hooks/usePublications/usePublications';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Publication from '../Publication/Publication';

interface ParticipantTracksProps {
  participant: Participant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  isLocalParticipant?: boolean;
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  participant,
  videoOnly,
  enableScreenShare,
  videoPriority,
  isLocalParticipant,
}: ParticipantTracksProps) {
  const publications = usePublications(participant);

  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;

  const localPublications = usePublications(localParticipant);
  const [isScreeShareTrack, setIsScreeShareTrack] = useState(false);

  let filteredPublications;

  useEffect(() => {
    if (
      enableScreenShare &&
      (publications.some((p) => p.trackName.includes('screen')) ||
        localPublications.some((p) => p.trackName.includes('screen')))
    ) {
      setIsScreeShareTrack(true);
    }
  }, [publications, enableScreenShare]);

  if (
    enableScreenShare &&
    (publications.some((p) => p.trackName.includes('screen')) ||
      localPublications.some((p) => p.trackName.includes('screen')))
  ) {
    // When displaying a screenshare track is allowed, and a screen share track exists,
    // remove all video tracks without the name 'screen'.
    filteredPublications = publications.filter((p) => p.trackName.includes('screen') || p.kind !== 'video');
    filteredPublications = [
      ...filteredPublications,
      ...localPublications.filter((p) => p.trackName.includes('screen') || p.kind !== 'video'),
    ];
  } else {
    // Else, remove all screenshare tracks
    filteredPublications = publications.filter((p) => !p.trackName.includes('screen'));
  }

  return (
    <>
      {filteredPublications.map((publication, index) => (
        <Publication
          isScreenShareTrack={isScreeShareTrack}
          key={index}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
      ))}
    </>
  );
}
