import { IconButton } from '@material-ui/core';
import { Sms } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import './MobileToggleChatButton.scss';

export const ANIMATION_DURATION = 700;

interface TogglePatientChatButtonProps {
  name?: string;
}

export default function MobileTogglePatientChatButton({ name }: TogglePatientChatButtonProps) {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { isChatWindowOpen, setIsChatWindowOpen, conversation, hasUnreadMessages } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsBackgroundSelectionOpen(false);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = () => setShouldAnimate(true);
      conversation.on('messageAdded', handleNewMessage);
      return () => {
        conversation.off('messageAdded', handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);

  return (
    <IconButton
      color="secondary"
      data-cy-chat-button
      onClick={toggleChatWindow}
      disabled={!conversation}
      className="btn-chat-mobile"
    >
      {hasUnreadMessages && <div className="unread-notification" />}
      <Sms />
    </IconButton>
  );
}
