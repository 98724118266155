import { IconButton, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import MicIcon from '../../../assets/icons/MicIcon';
import MicOffIcon from '../../../assets/icons/MicOffIcon';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { isSupported } from '@twilio/video-processors';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import BackgroundIcon from '../../../assets/icons/BackgroundIcon';
import FlipCameraIcon from '../../../assets/icons/FlipCameraIcon';
import InfoIconOutlined from '../../../assets/icons/InfoIconOutlined';
import SettingsIcon from '../../../assets/icons/SettingsIcon';
import StartRecordingIcon from '../../../assets/icons/StartRecordingIcon';
import StopRecordingIcon from '../../../assets/icons/StopRecordingIcon';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import { useAppState } from '../../../state';
import AboutDialog from '../../AboutDialog/AboutDialog';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';

const useStyles = makeStyles((theme: Theme) => ({
  audioButton: {
    // marginBottom: '1em',
    width: '56px',
    height: '56px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    transition: 'all .5s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
}));

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {

  const [menuOpen, setMenuOpen] = useState(false);
  const { setIsChatWindowOpen } = useChatContext();
  const isRecording = useIsRecording();
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();

  return (
    <IconButton
      color="secondary"
      onClick={() => {
        setIsBackgroundSelectionOpen(true);
        setIsChatWindowOpen(false);
        setMenuOpen(false);
      }}
    >
      <BackgroundIcon />
    </IconButton>
  );
}
