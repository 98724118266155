import React from 'react';

export default function VideoOnIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs></defs>
      <path
        fill="#fff"
        d="M85.6,33.38,73.18,42.9V28.82a5.92,5.92,0,0,0-5.92-5.92H14.48a5.93,5.93,0,0,0-5.93,5.92V71.18a5.93,5.93,0,0,0,5.93,5.92H67.26a5.92,5.92,0,0,0,5.92-5.92V57.1L85.6,66.62a3.64,3.64,0,0,0,5.85-2.89V36.27A3.64,3.64,0,0,0,85.6,33.38Z"
      />
    </svg>
  );
}
