import { IconButton, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useRef } from 'react';
import VideoOffIcon from '../../../assets/icons/VideoOffIcon';
import VideoOnIcon from '../../../assets/icons/VideoOnIcon';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) => ({
  videoButton: {
    // marginBottom: '1em',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    transition: 'all .5s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
}));

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <IconButton
      disabled={!hasVideoInputDevices || props.disabled}
      color="secondary"
      className={clsx(props.className, classes.videoButton)}
      onClick={toggleVideo}
    >
      {isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
    </IconButton>

    // <Button
    //   className={props.className}
    //   onClick={toggleVideo}
    //   disabled={!hasVideoInputDevices || props.disabled}
    //   startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
    // >
    //   {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'}
    // </Button>
  );
}
