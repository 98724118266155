import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import DrawerDragger from '../DrawerDragger/DrawerDragger';
import ChatInput from './ChatInput/ChatInput';
import './ChatWindow.scss';
import ChatWindowHeader from './ChatWindowHeader/ChatWindowHeader';
import MessageList from './MessageList/MessageList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      background: '#FFFFFF',
      zIndex: 9,
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid #E4E7E9',
      [theme.breakpoints.down('sm')]: {
        zIndex: 100,
        margin: '0',
        height: '60vh',
        overflowY: 'scroll',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        border: 'none',
      },
      margin: '32px 16px',
      height: 'calc(100vh - 108px)',
      position: 'relative',
    },
    hide: {
      display: 'none',
    },
    horizontalBarContainer: {
      width: '100vw',
      textAlign: 'center',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      top: 0,
      left: 0,
    },
    horizontalBar: {
      width: '20vw',
      height: '4px',
      borderRadius: '8px',
      backgroundColor: theme.palette.primary.main,
      margin: '8px 0',
    },
  })
);

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function ChatWindow() {
  const classes = useStyles();
  const { isChatWindowOpen, messages, conversation } = useChatContext();

  return (
    <div className={clsx(classes.chatWindowContainer + ' chat-window__container')}>
      <DrawerDragger />
      <ChatWindowHeader />
      <MessageList messages={messages} />
      <ChatInput conversation={conversation!} isChatWindowOpen={isChatWindowOpen} />
    </div>
  );
}
