import React from 'react';

export default function AvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path
        fill="#fff"
        d="M47.6,16.64a3.5,3.5,0,0,1-3.5,3.5h-19L35.62,30.67a3.5,3.5,0,0,1-4.95,4.95L20.14,25.09V41.83a3.5,3.5,0,0,1-7,0V16.64a3.82,3.82,0,0,1,.07-.68,2.63,2.63,0,0,1,.09-.3c0-.12.06-.24.11-.36s.11-.22.17-.33.09-.18.15-.27a3.18,3.18,0,0,1,.44-.53h0a3.6,3.6,0,0,1,.52-.43l.27-.14a3.65,3.65,0,0,1,.34-.18,3.38,3.38,0,0,1,.34-.11,2.8,2.8,0,0,1,.31-.09,3.36,3.36,0,0,1,.63-.06H44.1A3.5,3.5,0,0,1,47.6,16.64ZM44.1,79.86h-19L35.62,69.33a3.5,3.5,0,1,0-4.95-5L20.14,74.91v-19a3.5,3.5,0,1,0-7,0V83.36a3.82,3.82,0,0,0,.07.68,2.63,2.63,0,0,0,.09.3,2.47,2.47,0,0,0,.11.36c.05.11.11.22.17.33s.09.18.15.27a3.73,3.73,0,0,0,1,1l.27.14c.11.06.22.13.33.18s.24.07.36.11a2.63,2.63,0,0,0,.3.09,3.82,3.82,0,0,0,.68.07H44.1a3.5,3.5,0,1,0,0-7ZM83.36,52.4a3.5,3.5,0,0,0-3.5,3.5v19L69.33,64.38a3.5,3.5,0,1,0-5,5L74.91,79.86H58.17a3.5,3.5,0,0,0,0,7H83.36a3.82,3.82,0,0,0,.68-.07,2.63,2.63,0,0,0,.3-.09c.12,0,.24-.06.36-.11s.22-.12.33-.18l.27-.14a3.73,3.73,0,0,0,1-1c.06-.09.1-.18.15-.27s.12-.22.17-.33a2.47,2.47,0,0,0,.11-.36,2.63,2.63,0,0,0,.09-.3,3.82,3.82,0,0,0,.07-.68V55.9A3.5,3.5,0,0,0,83.36,52.4ZM86.79,16a2.63,2.63,0,0,0-.09-.3,2.47,2.47,0,0,0-.11-.36c0-.11-.11-.22-.17-.33s-.09-.18-.15-.27a3.69,3.69,0,0,0-.43-.53h0a3.6,3.6,0,0,0-.52-.43L85,13.59l-.33-.18c-.12-.05-.23-.07-.35-.11a2.8,2.8,0,0,0-.31-.09,3.36,3.36,0,0,0-.63-.06H58.17a3.5,3.5,0,0,0,0,7H74.91L64.38,30.67a3.5,3.5,0,0,0,5,4.95L79.86,25.09V41.83a3.5,3.5,0,0,0,7,0V16.64A3.82,3.82,0,0,0,86.79,16Z"
      />
    </svg>
  );
}
