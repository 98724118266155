import React from 'react';

export default function FileAttachmentIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path
        fill="#003d3b"
        d="M17.08,82.89a23.86,23.86,0,0,1,0-33.74L47.41,18.83A17.84,17.84,0,0,1,72.64,44.06l-29.8,29.8a12.08,12.08,0,0,1-16.69.5,11.8,11.8,0,0,1-.26-17l6.7-6.7a3.25,3.25,0,0,1,4.6,4.6L30.49,62a5.27,5.27,0,0,0-1.55,3.83,5.2,5.2,0,0,0,1.67,3.78,5.53,5.53,0,0,0,7.63-.36L68,39.46a11.33,11.33,0,1,0-16-16L21.7,53.75a17.37,17.37,0,0,0,.61,25.14c6.86,6.24,17.56,5.8,24.36-1L86.88,37.68a3.25,3.25,0,1,1,4.6,4.6L51.27,82.49C42,91.75,27.36,92.28,17.93,83.7,17.64,83.44,17.36,83.17,17.08,82.89Z"
      />
    </svg>
  );
}
