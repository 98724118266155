import React, { useEffect, useState } from 'react';
import { useAppState } from '../../state';
import './patient_detail.scss';

export default function MedicationTab() {
  const { patientInfo } = useAppState();
  const [currentRx, setCurrentRx] = useState<Array<any>>([]);
  const [pastRx, setPastRx] = useState<Array<any>>([]);

  useEffect(() => {
    let _medications = patientInfo?.medications ?? {};
    let _currentRx: any = [];
    let _pastRx: any = [];

    Object.keys(_medications).forEach((item) => {
      if (_medications[item] === 'active') {
        _currentRx.push(item);
      } else {
        _pastRx.push(item);
      }
    });

    setCurrentRx(_currentRx);
    setPastRx(_pastRx);
  }, [patientInfo]);

  return (
    <div className="consult-info__container">
      <div className="consult-info__section">
        <div>
          <div className="consult-info__section-label">Current Rx</div>
        </div>
        {currentRx.length > 0 ? (
          currentRx.map((treatment, index) => (
            <div key={index}>
              <h3>{treatment}</h3>
            </div>
          ))
        ) : (
          <div>
            <h3>N/A</h3>
          </div>
        )}
      </div>
      <div className="consult-info__section">
        <div>
          <div className="consult-info__section-label">Past Rx</div>
        </div>
        {pastRx.length > 0 ? (
          pastRx.map((treatment, index) => (
            <div key={index}>
              <h3>{treatment}</h3>
            </div>
          ))
        ) : (
          <div>
            <h3>N/A</h3>
          </div>
        )}
      </div>
    </div>
  );
}
