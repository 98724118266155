import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import Fullscreen from '../../../assets/icons/Fullscreen';
import FullscreenExit from '../../../assets/icons/FullscreenExit';
import useDevices from '../../../hooks/useDevices/useDevices';
import { useAppState } from '../../../state';

export default function ToggleFullscreenButton(props: { disabled?: boolean; className?: string }) {
  const { isFullScreenEnabled, setIsFullScreenEnabled } = useAppState();
  const { hasVideoInputDevices } = useDevices();

  const toggleFullScreen = useCallback(() => {
    setIsFullScreenEnabled(!isFullScreenEnabled);
  }, [isFullScreenEnabled, setIsFullScreenEnabled]);

  return (
    <IconButton
      disabled={!hasVideoInputDevices || props.disabled}
      color="secondary"
      className={clsx(props.className)}
      onClick={toggleFullScreen}
    >
      {!isFullScreenEnabled ? <Fullscreen /> : <FullscreenExit />}
    </IconButton>
  );
}
