import { User } from 'firebase';
import React, { createContext, useContext, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { Appointment, RecordingRules, RoomType, VcUser } from '../types';
import { initialSettings, Settings, SettingsAction, settingsReducer } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';

export interface StateContextType {
  error: TwilioError | Error | null;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  appointment: Appointment;
  setAppointment: any;
  userType: 'user' | 'doctor';
  setUserType: any;
  vcUser: VcUser;
  setVcUser: any;
  isAllowedJoin: boolean;
  setIsAllowedJoin: any;
  isFullScreenEnabled: boolean;
  setIsFullScreenEnabled: any;
  patientInfo: any;
  setPatientInfo: any;
  togglePatientInfo: any;
  setTogglePatientInfo: any;
  isPatientEndCallDialogVisible: any;
  setIsPatientEndCallDialogVisible: any;
  isDoctorEndCallDialogVisible: any;
  setIsDoctorEndCallDialogVisible: any;

  setError(error: TwilioError | Error | null): void;

  getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;

  signIn?(passcode?: string): Promise<void>;

  signOut?(): Promise<void>;

  setActiveSinkId(sinkId: string): void;

  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [appointment, setAppointment] = useState<Appointment>();
  const [userType, setUserType] = useState<string>('doctor');
  const [vcUser, setVcUser] = useState<VcUser>(null);
  const [isAllowedJoin, setIsAllowedJoin] = useState<boolean>(false);
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState<boolean>(false);
  const [patientInfo, setPatientInfo] = useState<any>(null);
  const [togglePatientInfo, setTogglePatientInfo] = useState<boolean>(false);
  const [isPatientEndCallDialogVisible, setIsPatientEndCallDialogVisible] = useState<boolean>(false);
  const [isDoctorEndCallDialogVisible, setIsDoctorEndCallDialogVisible] = useState<boolean>(false);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    appointment,
    setAppointment,
    userType,
    setUserType,
    vcUser,
    setVcUser,
    isAllowedJoin,
    setIsAllowedJoin,
    isFullScreenEnabled,
    setIsFullScreenEnabled,
    patientInfo,
    setPatientInfo,
    togglePatientInfo,
    setTogglePatientInfo,
    isPatientEndCallDialogVisible,
    setIsPatientEndCallDialogVisible,
    isDoctorEndCallDialogVisible,
    setIsDoctorEndCallDialogVisible,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            user_identity,
            room_name,
            create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
          }),
        }).then((res) => res.json());
      },
      updateRecordingRules: async (room_sid, rules) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/recordingrules';

        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room_sid, rules }),
          method: 'POST',
        })
          .then(async (res) => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message || 'There was an error updating recording rules'
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch((err) => setError(err));
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then((res) => {
        setRoomType(res.room_type);
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
