import React from 'react';
import './DrawerDragger.scss';

export default function DrawerDragger() {
  return (
    <div className="drawer-dragger">
      <div className="drawer-dragger__bar"></div>
    </div>
  );
}
