import axios from 'axios';

export const service = axios.create({
  timeout: 15000,
});

service.interceptors.request.use(
  async (config: any) => {
    const username = process.env.REACT_APP_USER_PORTAL_USERNAME;
    const password = process.env.REACT_APP_USER_PORTAL_PASSWORD;
    const basicAuth = 'Basic ' + btoa(username + ':' + password);

    config.headers.Authorization = basicAuth;

    if (process.env.NODE_ENV === 'production') {
      config.url = process.env.REACT_APP_USER_PORTAL_BASE_URL + config.url;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    let result = response.data;

    return result;
  },
  (error) => {
    return Promise.reject(error);
  }
);
