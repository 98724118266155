import { Button } from '@material-ui/core';
import React from 'react';
import { useAppState } from '../../state';
import './patient_detail.scss';

export default function SummaryTab() {
  const { appointment } = useAppState();

  const onClick = () => {
    window.open(appointment.user.patient_detail_url.replace(':3000', ''), '_blank');
  };

  return (
    <div className="consult-info__container">
      <div className="consult-info__section">
        <div className="consult-info__section-label">Consult type</div>
        <h3>{appointment?.reason ?? 'N/A'}</h3>
      </div>

      <div className="consult-info__section">
        <div className="consult-info__section-label">Appointment type</div>
        <h3>{appointment.appointment_type}</h3>
      </div>

      <div className="consult-info__view-history">
        <Button onClick={onClick} className="btn--reg" variant="contained">
          View History
        </Button>
      </div>
    </div>
  );
}
