import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useRef, useState } from 'react';
import { createMedicalNotes, getMedicalNote, updateMedicalNotes } from '../../api/note';
import { useAppState } from '../../state';
import './DoctorNoteSection.scss';

function DoctorNoteSection() {
  const { appointment } = useAppState();
  const [content, setContent] = useState('');
  const [note, setNote] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const componentMounted = useRef<boolean>(true);

  const onClickSaveNotes = async () => {
    setLoading(true);
    try {
      if (note?.id) {
        await updateMedicalNotes({
          id: note?.id ?? '',
          title: '',
          content,
        });
      } else {
        let response: any = await createMedicalNotes({
          user_uuid: appointment?.user?.uuid ?? '',
          doctor_uuid: appointment?.doctor_user?.uuid ?? '',
          title: '',
          content,
        });

        setNote(response);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getMedicalNote({
      user_uuid: appointment?.user?.uuid ?? '',
      doctor_uuid: appointment?.doctor_user?.uuid ?? '',
      appointment_type: appointment?.appointment_type ?? '',
      appointment_medications: appointment?.medication ?? '',
    })
      .then((response: any) => {
        if (componentMounted.current) {
          if (response.length > 0) {
            let lastNote = response[response.length - 1];
            setNote(response[response.length - 1]);
            setContent(lastNote.content);
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <div className="note-block">
      <h4>Notes</h4>
      <div>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          disabled={loading}
          config={{
            toolbar: [
              'heading',
              '|',
              'bold',
              'italic',
              'blockQuote',
              'link',
              'numberedList',
              'bulletedList',
              '|',
              'undo',
              'redo',
            ],
          }}
          onReady={(editor: any) => {
            // You can store the "editor" and use when it is needed.
            // console.log('Editor is ready to use!', editor);
          }}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();

            setContent(data);
            // console.log({ event, editor, data });
          }}
          onBlur={(event: any, editor: any) => {
            // console.log('Blur.', editor);
          }}
          onFocus={(event: any, editor: any) => {
            // console.log('Focus.', editor);
          }}
        />
      </div>
      <Divider light className="divider" />
      <div>
        <Button
          color="secondary"
          variant="contained"
          disabled={loading}
          onClick={onClickSaveNotes}
          className="btn-save-notes"
          startIcon={loading ? <CircularProgress color="primary" size={12} /> : ''}
        >
          Save notes
        </Button>
      </div>
    </div>
  );
}

export default DoctorNoteSection;
