import React from 'react';

export default function AvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <path
        fill="#fff"
        d="M67.67,65.4H84.36a3.5,3.5,0,1,0,0-7H64.17a3.08,3.08,0,0,0-.68.07,2.63,2.63,0,0,0-.3.09,2.47,2.47,0,0,0-.36.11l-.33.17-.27.15a3.45,3.45,0,0,0-1,1,2.34,2.34,0,0,0-.15.27,3.41,3.41,0,0,0-.17.33,2.47,2.47,0,0,0-.11.36c0,.1-.07.19-.09.29a4,4,0,0,0-.07.69V84.36a3.5,3.5,0,0,0,7,0v-19Zm0-33.1V13.37a3.5,3.5,0,0,0-7,0V35.83a3.82,3.82,0,0,0,.07.68c0,.1.06.2.09.3a2.47,2.47,0,0,0,.11.36c.05.11.11.22.17.33s.09.18.15.27a3.73,3.73,0,0,0,1,1l.27.14.33.18c.12,0,.24.07.36.11a2.63,2.63,0,0,0,.3.09,3.82,3.82,0,0,0,.68.07H84.36a3.5,3.5,0,0,0,0-7H67.67Zm-33.07,0H17.92a3.5,3.5,0,0,0,0,7H38.1a4,4,0,0,0,.69-.07c.1,0,.2-.06.3-.09a3.58,3.58,0,0,0,.35-.11,3.65,3.65,0,0,0,.34-.18l.26-.14a3.45,3.45,0,0,0,1-1c.06-.09.1-.18.15-.27a2.59,2.59,0,0,0,.17-.33,2.47,2.47,0,0,0,.11-.36c0-.1.07-.2.09-.3a3.08,3.08,0,0,0,.07-.68V13.37a3.5,3.5,0,1,0-7,0v19Zm0,33.09V84.36a3.5,3.5,0,0,0,7,0V61.9a3.23,3.23,0,0,0-.07-.69c0-.1-.06-.19-.09-.29a2.47,2.47,0,0,0-.11-.36,2.06,2.06,0,0,0-.17-.33A2.34,2.34,0,0,0,41,60a3.21,3.21,0,0,0-1-1,2.2,2.2,0,0,0-.26-.15,3.59,3.59,0,0,0-.34-.17,2,2,0,0,0-.35-.11l-.3-.09a3.23,3.23,0,0,0-.69-.07H17.92a3.5,3.5,0,1,0,0,7H34.6Z"
      />
    </svg>
  );
}
